import React, { Component } from 'react';
import { animateScroll, Element, scroller } from 'react-scroll';
import _ from 'lodash';
import 'whatwg-fetch';
//import validator from 'validator';

import HelmetEx from '../components/helmet-ex';
import Layout from '../components/layout';
import GoogleMap from '../components/map';
import Config from '../config';

let Modes = {
    EDIT: 'edit',
    BUSY: 'busy',
    SUCCESS: 'success',
    ERROR: 'error'
}

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: {
                errors: []
            },
            mode: Modes.EDIT
        }

        this.scroll = animateScroll;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            message: _.merge({}, this.state.message, {
                errors: [],
                valid: true,
                [name]: value
            })
        });
    }

    scrollToView = (element) => {
        scroller.scrollTo(element || 'form-top', {
            duration: 1500,
            delay: 100,
            smooth: "easeInOutQuint"
        });
    }

    validate = () => {
        var validated = true;

        // if (!this.state.message.email || !validator.isEmail(this.state.message.email)) {
        //     this.scrollToView();
        //     this.setState({
        //         message: _.merge({}, this.state.message, {
        //             errors: ['Please specify a valid email address'],
        //             // valid: false
        //         })
        //     });

        //     return validated = false;
        // }

        if (window.grecaptcha.getResponse(this.grecaptchaId) === '') {
            this.scrollToView();
            this.setState({
                message: _.merge({}, this.state.message, {
                    errors: ['You must check the "I\'m not a robot" box'],
                    // valid: false,
                    invalidElement: 'recaptcha'
                })
            });

            return validated = false;
        }

        return validated;
    }

    componentDidMount = () => {
        this.renderRecaptcha();
    }

    componentDidUpdate = () => {
        this.renderRecaptcha();
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        if (!this.validate())
            return;

        let url = Config.Services.Messaging.Url;
        let message = _.merge({}, this.state.message, {
            recaptcha: window.grecaptcha.getResponse(this.grecaptchaId)
        });

        let request = {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(message)
        };
        request.headers = new Headers();
        request.headers.append('Content-Type', 'application/json; charset=utf-8');
        this.setState({ mode: Modes.BUSY });
        let $this = this;

        fetch(url, request).then((result) => {
            if (result.ok) {
                $this.setState({ mode: Modes.SUCCESS, message: {} });
            } else {
                console.error('SERVER ERROR RESULT:', result);
                result.json().then((json) => {
                    if (result.status === 400) {
                        let errors = [];
                        for (var property in json.errors) {
                            errors = errors.concat(json.errors[property]);
                        }
                        return $this.setState({
                            mode: Modes.EDIT,
                            message: _.merge({}, this.state.message, {
                                errors: errors
                            })
                        });
                    }
                    console.error('SERVER ERROR', json);
                    $this.setState({ mode: Modes.ERROR });
                });
            }
        }).catch(() => {
            $this.setState({ mode: Modes.ERROR });
        })
    }

    renderRecaptcha = () => {
        if (this.state.mode !== Modes.EDIT || this.refs.recaptchaDiv.innerHTML) return;
        this.grecaptchaId = window.grecaptcha.render(this.refs.recaptchaDiv, {
            'sitekey': Config.Services.Recaptcha.Key
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            message: _.merge({}, this.state.message, {
                errors: [],
                valid: true,
                [name]: value
            })
        });
    }

    render() {
        return (
            <Layout>
                <div className="contact-page">
                    <HelmetEx>
                        <div metadata="title">Viktoria Interior | Contact Us | Elmwood Park, NJ</div>
                        <div metadata="keywords"></div>
                        <div metadata="description"></div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="col-12 text-center">
                                        <h1>CONTACT US</h1>
                                        <h3>Stop by and say hello.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row mb-4">
                            <div className="col-12 col-md-6">
                                <GoogleMap />
                            </div>
                            <div className="col-12 col-md-6">
                                {/* <h2>WE LOOK FORWARD TO SEEING YOU!</h2> */}
                                <h3>Stop by our showroom to find out more.</h3>
                                <div className="row showroom">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <i className="fas fa-map-marker-alt fa-2x"></i>
                                                <h4>ADDRESS</h4>
                                                <address>
                                                    248 Broadway A<br />
                                                    Elmwood Park, NJ 07407
                                                </address>
                                            </div>
                                            <div className="col-12">
                                                <i className="fas fa-phone fa-2x"></i>
                                                <h4>PHONE</h4>
                                                <a href="tel:201-475-7711" className="phone">
                                                    (201) 475-7711
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <i className="fas fa-clock fa-2x"></i>
                                        <h4>HOURS</h4>
                                        <dl className="row">
                                            <dt className="col-6">Monday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Tuesday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Wednesday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Thursday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Friday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Saturday:</dt><dd className="col-6">8am-5pm</dd>
                                            <dt className="col-6">Sunday:</dt><dd className="col-6">Closed</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Or send us a message now</h2>
                            </div>
                        </div>
                        <div className="mt-4">

                            {(this.state.mode === Modes.EDIT || this.state.mode === Modes.BUSY) && (
                                <form onSubmit={this.onFormSubmit} className="row">
                                    {(this.state.message.errors || []).length > 0 && (
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                Could not send because:
                                                <ul className="list-inline">
                                                    {this.state.message.errors.map((error, index) => {
                                                        return <li className="list-inline-item" key={index}>{error}</li>
                                                    })}
                                                </ul>
                                                {/* <p className="danger-paragraph">{this.state.message.errors}</p> */}
                                            </div>
                                        </div>
                                    )}

                                    {/* Left Column */}
                                    <div className="col-12 col-md-6 form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <input name="name" className="form-control" onChange={this.handleInputChange}
                                                    placeholder="Your Name" />
                                            </div>
                                            <div className="col-12">
                                                <input type="text" className="form-control" onChange={this.handleInputChange}
                                                    name="email" placeholder="Your Email" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right Column */}
                                    <div className="col-12 col-md-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea className="form-control" onChange={this.handleInputChange}
                                                    name="body" placeholder="Your Message" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 text-md-left text-right">
                                        <Element name="recaptcha"></Element>
                                        <div ref="recaptchaDiv" className="recaptcha"></div>
                                    </div>
                                    <div className="col-12 col-md-6 text-right">
                                        <button type="submit" disabled={this.state.mode === Modes.BUSY} className="main-button">
                                            {this.state.mode === Modes.EDIT && (
                                                <span>SEND</span>
                                            )}
                                            {this.state.mode === Modes.BUSY && (
                                                <React.Fragment>
                                                    <span>SENDING&nbsp;</span>
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </React.Fragment>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                            {(this.state.mode === Modes.ERROR || this.state.mode === Modes.SUCCESS) && (
                                <div className="row">
                                    {this.state.mode === Modes.ERROR && (
                                        <div className="col-12">
                                            <div className="alert alert-danger text-center">
                                                <i className="fa fa-info fa-2x" aria-hidden="true"></i>
                                                <h5 className="uppercase">Could not send message</h5>
                                                <p>
                                                    Please try again later, or just give us a call. We apologize for the inconvenience.
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.mode === Modes.SUCCESS && (
                                        <div className="col-12">
                                            <div className="alert alert-success text-center">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <i className="fa fa-check fa-4x" aria-hidden="true"></i>
                                                        <h4>Message received</h4>
                                                        <p>We will get back to you within one business day.</p>
                                                    </div>
                                                    <div className="col-12 text-right">
                                                        <button type="submit" className="btn btn-default" onClick={() => this.setState({ mode: Modes.EDIT })}>Send Another</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default Contact; 