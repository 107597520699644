import React, { Component } from 'react';

class GoogleMap extends Component {

    state = {
        width: 600
    }

    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener("resize", this._updateDimensions);
        this._updateDimensions();
    }

    componentWillMount = () => {
        this._updateDimensions();
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this._updateDimensions);
    }

    render = () => {
        return (
            <div className="map-component" ref={this.componentRef}>
                <iframe title="248 Broadway A, Elmwood Park, NJ 07407, USA" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.7800305011992!2d-74.12091228433991!3d40.9205687327659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2fb88cf7604df%3A0xb9f44540e563e66!2sViktorija+Interior+LLC!5e0!3m2!1sen!2sus!4v1561772574718!5m2!1sen!2sus"
                    width={this.state.width} height="450" frameBorder="0" allowFullScreen></iframe>
            </div>
        );
    }

    _updateDimensions = () => {
        const node = this.componentRef.current;
        if (!node) return;
        this.setState({ width: node.clientWidth });
    }
}

export default GoogleMap;